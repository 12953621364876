
import React from 'react';
import Contact from "../components/contact"
import "../components/layout.css"
import "../components/tejas.css"

const Support = () => {

  return(
    <Contact show={true} description={"Please let me know how I can help!"}/>
  )
}
Support.propTypes = {

}
export default Support
